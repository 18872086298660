import {useStaticQuery, graphql} from 'gatsby';

export const useHomeContent = () => {
  const {allHomeJson} = useStaticQuery(
    graphql`
      query HomeContent {
        allHomeJson {
          nodes {
            aboutUs {
              button {
                link
                text
              }
              content
              heading
            }
            hero {
              button {
                link
                text
              }
              heading
            }
            services {
              heading
              services {
                description
                title
              }
            }
          }
        }
      }
    `,
  );
  return allHomeJson.nodes;
};

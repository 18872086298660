import React from 'react';
import {graphql, useStaticQuery, Link} from 'gatsby';
import Img from 'gatsby-image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ServiceCard from '../serviceCard/ServiceCard';
import './HomeContent.less';

const HomeContent = ({content}) => {
  const {file} = useStaticQuery(
    graphql`
      query {
        file(relativePath: {eq: "foreza2.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  );

  return (
    <Container fluid className="homeContent my-5">
      <h2 className="homeContent__heading mb-3">{content?.aboutUs?.heading}</h2>
      <div className="homeContent__borderBottom mb-5" />
      <Row className="homeContent__aboutUs align-items-center">
        <Col xs={12} md={12} lg={6}>
          <div>
            <Img
              fluid={file.childImageSharp.fluid}
              className="homeContent__image"
              alt="Masina de forat"
            />
          </div>
        </Col>
        <Col xs={12} md={12} lg={5}>
          {content?.aboutUs?.content?.map((item, index) => (
            <p
              className="homeContent__paragraph"
              key={index}
              dangerouslySetInnerHTML={{
                __html: item,
              }}
            />
          ))}
          <Button
            variant="outline-warning"
            as={Link}
            to={content?.aboutUs?.button?.link}
          >
            {content?.aboutUs?.button?.text}
          </Button>
        </Col>
      </Row>
      <Container>
        <h2 className="homeContent__heading mt-5 mb-3">
          {content?.services?.heading}
        </h2>
        <div className="homeContent__borderBottom mb-4" />
        <Row className="homeContent__list mt-4">
          {content?.services?.services?.map((item, index) => (
            <Col xs={12} md={6} lg={4} className="my-3" key={index}>
              <ServiceCard title={item.title} description={item.description} />
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
};

export default HomeContent;

import React from 'react';
import {Link} from 'gatsby';
import Button from 'react-bootstrap/Button';
import Layout from '../components/layout/Layout';
import SEO from '../components/seo';
import Hero from '../components/hero/Hero';
import HomeContent from '../components/homeContent/HomeContent';
import {useHomeContent} from '../graphql/home/home';

const Home = () => {
  const [homeContent] = useHomeContent();
  const heroContent = (
    <>
      <h1>{homeContent?.hero?.heading}</h1>
      <Button variant="warning" as={Link} to={homeContent?.hero?.button?.link}>
        <b>{homeContent?.hero?.button?.text}</b>
      </Button>
    </>
  );

  return (
    <Layout>
      <SEO title="Acasă" />
      <Hero content={heroContent} />
      <HomeContent content={homeContent} />
    </Layout>
  );
};

export default Home;

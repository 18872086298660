import React from 'react';
import Card from 'react-bootstrap/Card';
import './ServiceCard.less';

const ServiceCard = ({title, description}) => {
  return (
    <Card className="serviceCard">
      <Card.Body>
        <Card.Title className="serviceCard__title">
          <span>{title}</span>
        </Card.Title>
        <Card.Text>{description}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ServiceCard;

import React from 'react';
import Container from 'react-bootstrap/Container';
import './Hero.less';

const Hero = ({content}) => {
  return (
    <section className="hero">
      <Container fluid>
        <div className="hero__content">{content}</div>
      </Container>
    </section>
  );
};

export default Hero;
